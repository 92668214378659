var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",attrs:{"autocomplete":"off","tag":"v-form"}},[_c('v-card',[_c('v-toolbar',{staticClass:"cw-business-loan-signees-dialog__toolbar",attrs:{"color":"transparent","flat":""}},[_c('v-toolbar-title',[_c('translate',[_vm._v("Add signee")])],1),_c('v-spacer'),(_vm.data.openFullscreen)?_c('v-btn',{attrs:{"id":"cancel-dialog","icon":""},on:{"click":function($event){_vm.onDialogClose();
          _vm.$eventLogger.clickEvent($event);}}},[_c('v-icon',[_vm._v("close")])],1):_vm._e()],1),_c('v-card-text',{staticClass:"pt-0 px-6"},[_c('v-row',{staticClass:"cw-business-loan-signees__signees"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":_vm.data.hasErrors ? 'error' : 'info',"border":"left","dense":"","text":""}},[_c('translate',[_vm._v(" Add the required number of people specified in the autograph clause with their contact information. ")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"text-body-2 font-weight-bold"},[_c('translate',[_vm._v(" The business signature clause ")])],1),_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(_vm.data.procurationAbstractDescription)+" ")])]),_c('v-col',{attrs:{"id":"cw-business-loan-signees__available-signees","cols":"12","sm":"6"}},[_c('cw-select',{attrs:{"id":"available-signees","items":_vm.data.availableSignees,"label":_vm.availableSigneesLabel,"item-value":"ssn","rules":"required","attach":"","prevent-set-data":"","return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"cw-business-loan-signees__item",class:("available-signees__option--" + (item.ssn))},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('p',{staticClass:"mb-0"},[_c('cw-chips',{staticClass:"px-0",attrs:{"items":item.roles,"left":""}})],1)])]}},{key:"selection",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.signeeData),callback:function ($$v) {_vm.signeeData=$$v},expression:"signeeData"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}}),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('cw-text-field',{attrs:{"id":"signee-email","extend-rules":false,"rules":("required|email|error:" + (_vm.hasError('email'))),"hint":_vm.signeeEmailHint,"label":_vm.signeeEmailLabel,"type":"text","anonymize":"","prevent-set-data":""},on:{"focus":function($event){return _vm.removeApiError(("signees." + (_vm.data.index) + ".email"))}},model:{value:(_vm.signee.email),callback:function ($$v) {_vm.$set(_vm.signee, "email", $$v)},expression:"signee.email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('cw-text-field',{attrs:{"id":"signee-phone","extend-rules":false,"rules":("required|phone|error:" + (_vm.hasError('phone'))),"hint":_vm.signeePhoneHint,"label":_vm.signeePhoneLabel,"type":"text","anonymize":"","prevent-set-data":""},on:{"focus":function($event){return _vm.removeApiError(("signees." + (_vm.data.index) + ".phone"))}},model:{value:(_vm.signee.phone),callback:function ($$v) {_vm.$set(_vm.signee, "phone", $$v)},expression:"signee.phone"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(!_vm.data.openFullscreen)?_c('v-btn',{attrs:{"id":"cancel-dialog","text":""},on:{"click":function($event){_vm.onDialogClose();
          _vm.$eventLogger.clickEvent($event);}}},[_c('translate',[_vm._v("Cancel")])],1):_vm._e(),_c('v-btn',{attrs:{"id":"add-signee","disabled":_vm.$wait.waiting('signees__add-item'),"loading":_vm.$wait.waiting('signees__add-item'),"color":"primary","text":""},on:{"click":function($event){_vm.onAddItem();
          _vm.$eventLogger.clickEvent($event);}}},[_c('translate',[_vm._v("Add")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }