<template>
  <validation-observer
    ref="observer"
    autocomplete="off"
    tag="v-form"
  >
    <v-card>
      <v-toolbar
        class="cw-business-loan-signees-dialog__toolbar"
        color="transparent"
        flat
      >
        <v-toolbar-title><translate>Add signee</translate></v-toolbar-title>
        <v-spacer/>
        <v-btn
          v-if="data.openFullscreen"
          id="cancel-dialog"
          icon
          @click="
            onDialogClose();
            $eventLogger.clickEvent($event);
          "
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-0 px-6">
        <v-row class="cw-business-loan-signees__signees">
          <v-col cols="12">
            <v-alert
              :type="data.hasErrors ? 'error' : 'info'"
              border="left"
              dense
              text
            >
              <translate>
                Add the required number of people specified in the autograph clause with their
                contact information.
              </translate>
            </v-alert>
          </v-col>

          <v-col cols="12">
            <h3 class="text-body-2 font-weight-bold">
              <translate>
                The business signature clause
              </translate>
            </h3>
            <p class="ma-0">
              {{ data.procurationAbstractDescription }}
            </p>
          </v-col>

          <v-col
            id="cw-business-loan-signees__available-signees"
            cols="12"
            sm="6"
          >
            <cw-select
              id="available-signees"
              v-model="signeeData"
              :items="data.availableSignees"
              :label="availableSigneesLabel"
              item-value="ssn"
              rules="required"
              attach
              prevent-set-data
              return-object
            >
              <template #item="{ item }">
                <div
                  :class="`available-signees__option--${item.ssn}`"
                  class="cw-business-loan-signees__item"
                >
                  <p class="mb-0">
                    {{ item.name }}
                  </p>
                  <p class="mb-0">
                    <cw-chips
                      :items="item.roles"
                      class="px-0"
                      left
                    />
                  </p>
                </div>
              </template>
              <template #selection="{ item }">
                {{ item.name }}
              </template>
            </cw-select>
          </v-col>

          <v-col cols="12" sm="6"/>

          <v-col cols="12" sm="6">
            <cw-text-field
              id="signee-email"
              v-model="signee.email"
              :extend-rules="false"
              :rules="`required|email|error:${hasError('email')}`"
              :hint="signeeEmailHint"
              :label="signeeEmailLabel"
              type="text"
              anonymize
              prevent-set-data
              @focus="removeApiError(`signees.${data.index}.email`)"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <cw-text-field
              id="signee-phone"
              v-model="signee.phone"
              :extend-rules="false"
              :rules="`required|phone|error:${hasError('phone')}`"
              :hint="signeePhoneHint"
              :label="signeePhoneLabel"
              type="text"
              anonymize
              prevent-set-data
              @focus="removeApiError(`signees.${data.index}.phone`)"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-if="!data.openFullscreen"
          id="cancel-dialog"
          text
          @click="
            onDialogClose();
            $eventLogger.clickEvent($event);
          "
        >
          <translate>Cancel</translate>
        </v-btn>
        <v-btn
          id="add-signee"
          :disabled="$wait.waiting('signees__add-item')"
          :loading="$wait.waiting('signees__add-item')"
          color="primary"
          text
          @click="
            onAddItem();
            $eventLogger.clickEvent($event);
          "
        >
          <translate>Add</translate>
        </v-btn>
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'CwBusinessLoanSigneesDialogSigneeFields',

  components: {
    'cw-chips': () => import('@shared/components/Chips'),
    'cw-select': () => import('@shared/components/inputs/CwSelect'),
    'cw-text-field': () => import('@shared/components/inputs/CwTextField'),
    'validation-observer': ValidationObserver,
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    signeeData: null,
    signee: {
      email: null,
      phone: null,
    },
  }),

  computed: {
    ...mapState({
      apiErrors: state => state.application.apiErrors,
    }),

    /**
     * Gettext translations
     */
    availableSigneesLabel: vm => vm.$gettext('The persons who hold the rights of signature'),
    signeeEmailHint: vm => vm.$gettext('Email in the form of name@example.com'),
    signeeEmailLabel: vm => vm.$gettext('Email'),
    signeePhoneHint: vm => vm.$gettext('Phone number in the form of 0401234567'),
    signeePhoneLabel: vm => vm.$gettext('Phone number'),

    apiError() {
      return this.apiErrors
        .find(error => error.startsWith(`signees.${this.data.index}`));
    },
  },

  methods: {
    ...mapActions({
      removeApiError: 'application/removeApiError',
    }),

    hasError(prop) {
      if (!this.apiError) return false;

      return this.apiError.split('.').pop() === prop;
    },

    async onAddItem() {
      const valid = await this.$refs.observer.validate();

      if (!valid) return;

      const { $bus, signee, signeeData } = this;

      $bus.$emit('SIGNEES_ITEM_ADD', ({
        type: 'signee',
        data: {
          ...signeeData,
          ...signee,
        },
      }));
    },

    onDialogClose() {
      this.$bus.$emit('SIGNEES_DIALOG_CLOSE');
    },
  },
};
</script>
